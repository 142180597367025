.image-gallery-thumbnails {
    width: 80%;
    margin: auto;
}

.image-gallery-thumbnails-wrapper {
    margin-top: 30px;
}


@screen md {
    .image-gallery-thumbnails {
        width: 60%;
    }
}

@screen lg {
    .image-gallery-thumbnails {
        width: 50%;
    }
}

@screen xl {
    .image-gallery-thumbnails {
        width: 40%;
    }
}

.swiper-slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
}

.swiper-slide-active{
    transform: scale(1);
    opacity: 1;
}

.slideWrapper {
    display: flex;
    justify-content: center;
}



.swiper-button-next,
.swiper-button-prev {
    display: none !important;
    --swiper-navigation-color: rgba(180, 162, 136, 1);
}

@screen lg {
    .swiper-container {
        width: 60%;
    }
}

@screen xl {
    .swiper-container {
        width: 60%;
    }
}


@screen lg {
    .swiper-button-next,
    .swiper-button-prev {
        display: inline-block !important;
    }
}

.image-gallery-svg {
    @apply text-white;
    height: 50%;
}

.slick-list, .Slide, .slick-cloned, .slick-slide {
    border-color: none !important;
}