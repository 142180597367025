@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import 'components/content';
@import 'components/slider';
@import 'components/fonts';
@import 'components/slick';
@import 'components/slick-theme';
@import 'components/form';
@import 'components/animations';


html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

*,*:focus,*:hover{
    outline:none;
}

.filter-contrast-0_4 {
    filter: contrast(0.4) !important;
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
    body {
        font-size: 1rem;
    }
} 