@import "~animate.css";
@import "~animate.css/source/_base";
@import "~animate.css/source/fading_entrances/fadeIn";
@import "~animate.css/source/fading_exits/fadeOut";
@import "~animate.css/source/fading_entrances/fadeInDown";
@import "~animate.css/source/sliding_entrances/slideInLeft";
@import "~animate.css/source/sliding_exits/slideOutRight";
@import "~animate.css/source/sliding_entrances/slideInRight";
@import "~animate.css/source/sliding_exits/slideOutLeft";
// image scale hover sm

.base-image-scale-hover-sm {
    transition: transform 0.1s;

    &:hover {
        transform: scale(1.05);
    }
}

@layer utilities {
    @variants hover, focus {
        .filter-contrast-15 {
            filter: contrast(0.15);
        }
    }
}