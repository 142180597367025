// Provides the content area and base padding
//
// .base-content-width-wide
// .base-content-width-wide-1
// .base-content-width-wide-2
// .base-content-width-wide-3
// .base-content-width
// .base-content-padding

@responsive {

    // base content wide width

    .base-content-width-wide,
    .base-content-width-wide-3 {
        @apply px-8;
        @apply w-screen;
    }
    @screen md {
        .base-content-width-wide,
        .base-content-width-wide-3 {
            @apply px-10;
        }
    }
    @screen lg {
        .base-content-width-wide,
        .base-content-width-wide-3 {
            @apply px-0;
            max-width: 1024px;
        }
    }
    @screen xl {
        .base-content-width-wide,
        .base-content-width-wide-3 {
            @apply px-0;
            max-width: 1600px;
        }
    }

    // base-content-wide-width-1

    .base-content-width-wide-1 {
        @apply px-8;
        @apply w-screen;
    }
    @screen md {
        .base-content-width-wide-1 {
            @apply px-10;
        }
    }
    @screen lg {
        .base-content-width-wide-1 {
            @apply px-0;
            max-width: 1080px;
        }
    }
    @screen xl {
        .base-content-width-wide-1 {
            @apply px-0;
            max-width: 1500px;
        }
    }

    // base-content-wide-width-2

    .base-content-width-wide-2 {
        @apply px-8;
        @apply w-screen;
    }
    @screen md {
        .base-content-width-wide-2 {
            @apply px-10;
        }
    }
    @screen lg {
        .base-content-width-wide-2 {
            @apply px-0;
            max-width: 824px;
        }
    }
    @screen xl {
        .base-content-width-wide-2 {
            @apply px-0;
            max-width: 1180px;
        }
    }

    // base content width

    .base-content-width {
        @apply px-8;
        @apply w-screen;
    }
    @screen md {
        .base-content-width {
            @apply px-10;
        }
    }
    @screen lg {
        .base-content-width {
            @apply px-0;
            max-width: 824px;
        }
    }
    @screen xl {
        .base-content-width {
            @apply px-0;
            max-width: 980px;
        }
    }

    // base padding content

    .base-content-padding {
        @apply mx-4;
        @apply py-8;
    }
    @screen md {
        .base-content-padding {
            @apply mx-0;
            @apply py-10;
        }
    }
    @screen lg {
        .base-content-padding {
            @apply mx-0;
            @apply py-10;
        }
    }
    @screen xl {
        .base-content-padding {
            @apply mx-0;
            @apply py-10;
        }
    }
}