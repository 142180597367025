@font-face {
    font-family: Kozgopro Bold;
    src: url(/fonts/kozgopro-bold.otf);
}

@font-face {
    font-family: Kozgopro Medium;
    src: url(/fonts/kozgopro-medium.otf);
}

@font-face {
    font-family: Kozuka Gothic Pro L;
    src: url(/fonts/kozuka-gothic-pro-l.otf);
}

@font-face {
    font-family: Quas Stencil;
    src: url(/fonts/quas-stencil.otf);
}

body {
    font-family: 'Kozuka Gothic Pro L', sans-serif;
}
